import React, {useState} from "react";
import { graphql } from 'gatsby';
import Layout from "@components/kaigai-fx/layout"
import FixedFoot from "@components/kaigai-fx/fixedFooter.js";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from "html-react-parser"
import { useLocation } from "@reach/router"
import Animation from "@components/kaigai-fx/animation.js";
import LazyLoad from 'react-lazy-load';

// Import SVG
import icon_t_SVG1 from '@images/kaigai-fx/top_t_icon1.svg';
import icon_post_SVG3 from '@images/kaigai-fx/post_icon3.svg';

// コンポーネント呼び出し
import { SearchCategList } from "@components/kaigai-fx/search-categList.js"; // カテゴリ検索機能
import { WordSearch } from "@components/kaigai-fx/word-search.js";
import { ProfileContents } from "@components/kaigai-fx/profile.js";
import { SeoContents } from "@components/kaigai-fx/seo-data.js" // Seo情報設定
import { RelatedContents } from "@components/kaigai-fx/related-article.js"; // 関連記事取得・形成
import { ArchiveVisualComponent } from "@components/kaigai-fx/archiveVisualComponent.js";
import { BannerDataComponent } from "@components/kaigai-fx/bannerDataComponent.js";

const SearchResults = ({data}) => {

  const indexUrl = '/kaigai-fx/'
  const searchList = SearchCategList().Main; // カテゴリ検索リスト
  const wordSearchContentPC = WordSearch().PC; // ワード検索PC
  const wordSearchContentMB = WordSearch().MB; // ワード検索MB
    
  let setArray = data.allWpPostData.edges;
  setArray = setArray.filter(item => !item.node.fxTags.nodes.some(tag => tag.slug === 'display-on-top')); // TOP表示の記事を削除

  // Seo情報設定
  const pathName = 'search-results';
  const seoTag = SeoContents({ Path:pathName });

  const location = useLocation()
  const urlItem = location.href;
  let item = '#';
  let name;

  let index1, status;
  if ( urlItem !== undefined ){
    index1 = urlItem.indexOf(item);
  } else {
    index1 = -1;
  }

  if (index1 !== -1 ) { // 検索結果ありの場合
    let check = decodeURI(urlItem.substring(index1 + 1));
    name = check; // 検索ワードセット
    let searchWord;
    let resultArray = [];
    setArray.forEach((result) => {
      searchWord = result.node.title + result.node.content;
        if ( searchWord.toLowerCase().indexOf(check.toLowerCase()) !== -1) {
          resultArray.push(result)
        }
    })
    setArray = resultArray;
    status = 'loadcomp'
    name = "「" + name + "」の検索結果：" + setArray.length + "件"; // 表示名作成
  } else if ( index1 === -1 ) { // 検索結果がない場合
    name = `検索中`
    status = 'loading'
    setArray = [];
  }

  // バナー情報取得（初回レンダリング時のみ作動）
  const getBannerData = data.imagesettingData.edges.filter(item => item.node.slug === 'banner')[0];
  const getArchiveVisual = data.imagesettingData.edges.filter(item => item.node.slug === 'archive-visual')[0];
  const [bannerArray_side] = useState(() => BannerDataComponent('banner', 'banner_area_3', getBannerData));

  // 関連記事取得(components/related-article.js)
  let tagArray = setArray.flatMap(item => item.node.fxTags.nodes.map(tag => ({ name: tag.name, slug: tag.slug }))); // 全タグ取得して配列化
  tagArray = Array.from(new Set(tagArray.map(tag => tag.slug))).map(slug => tagArray.find(tag => tag.slug === slug)); // 重複削除
  tagArray = tagArray.filter(item => item.name.indexOf('Q&A') === -1); // Q&Aタグ削除
  const relatedList = RelatedContents([''], tagArray, '', getArchiveVisual); 

  // サムネイル画像設定
  const thumData = ArchiveVisualComponent(setArray, 'thumbnail', getArchiveVisual);

  // プロフィール
  const profilePC = ProfileContents().PC 
  const profileMB = ProfileContents().MB

  return (
    <>
      <Layout>
        {seoTag}
        <div className="md:max-w-6xl md:flex justify-between mx-auto pt-5 md:pt-12"> 
          <div className="max-w-4xl pb-5 px-5 mb-10 md:pb-0 md:mb-0 w-full">
            <div className="text-left mb-3 mx-auto flex items-center">
              <p><img  alt="" src={icon_t_SVG1}/></p>
              <h1 className="ml-5 font-semibold tracking-wide text-[24px] md:text-[56px] text-[#36A7C3] table" itemProp="headline">{ name }</h1>
            </div>

            <p className="text-[12px] mb-[20px] md:mb-[30px]">
              {/* パンくず */}
              <div className="flex mb-2 md:mb-3">
                {status !== 'loading'? 
                  <>
                    <img className="pr2" alt="" src={icon_post_SVG3}/>
                    <a href={indexUrl} className="px-2 hover:text-gray-400">ホーム</a>
                    <span className="pr-2 mx-0 my-auto inset-y-0 md:m-0">＞</span>
                    <span className="pr-2">{name}</span>
                  </>
                :
                  <></>
                }
              </div>           
              {/* タグ */}
              <div className="flex flex-wrap">
                {tagArray.map((result) =>
                  <a href={`${indexUrl}tags/${result.slug}/`} className="mb-2 mr-2 text-white bg-[#36A7C3] text-[9px] rounded px-2 py-1 text-center">{result.name}</a>
                )}
              </div>
            </p>

            <ol style={{ listStyle: `none` }}>
              <Animation customType='falling-item' loop='loop'>
                {/* 検索記事一覧 */}
                <div className="mx-auto md:flex max-w-xl md:flex-wrap items-center md:max-w-5xl justify-start">
                  {setArray.map((result, index) =>
                    <li className="md:w-1/3 md:aspect-square" style={{marginBottom:15 ,padding:10}}>
                    <article className="post-list-item !m-0 " style={{ height: '100%' }} itemScope itemType="http://schema.org/Article">
                      <a href={indexUrl + result.node.slug + "/"} itemProp="url" className="Campaign w-[100%]">
                        <div className="w-full" style={{ height: '55%' }}>
                          {/* 画像部分 */}
                          {result.node.featuredImage? 
                            <GatsbyImage
                              image={getImage(result.node.featuredImage.node.localFile.childImageSharp.gatsbyImageData)} alt={result.node.title}
                              className='aspect-[25/14] h-full block w-full h-full rounded-blog overflow-hidden'>
                            </GatsbyImage>
                          :
                            <div className='relative overflow-hidden rounded-blog'>
                              <div className='absolute z-10 h-full w-full flex justify-center items-center'>
                                <p className={`relative ${thumData[index].textColor} ${thumData[index].fontSize.mb} ${thumData[index].fontSize.pc} ${thumData[index].fontSize.max} font-[900] text-center`} style={{top:`${thumData[index].position}px`}}>
                                  {thumData[index].text2 !== '' && thumData[index].text2 !== undefined?
                                    <p className='relative top-[6px] md:top-[6px] leading-[1.4]'>{thumData[index].text1}<br/>{thumData[index].text2}</p>
                                    :
                                    <>{thumData[index].text1}</>            
                                  }
                                </p>
                              </div>
                              <LazyLoad className='' offset={300}>
                                <img className="w-full object-cover aspect-[25/14]" alt={thumData[index].alt} src={thumData[index].img}/>
                              </LazyLoad>
                            </div>
                          }
                        </div>
                        {/* タイトル部分 */}
                        <div className="bg-white w-full text-left" >
                          <p className="CampP md:text-xs text-[15px] font-bold mb-4 py-2 text-left " style={{ height: '8%' }}>{result.node.title}</p>
                          <p className="text-[12px] text-left  md:text-[10px]  pb-4" >{parse(result.node.excerpt)}</p>
                        </div>
                      </a>
                    </article>
                  </li>
                  )}
                </div>
              </Animation>
            </ol>

          </div>

          <aside className="column2_filed px-5 md:pt-0 pt-8">
            <div className="toc_mt">

              {/* バナー画像（横） */}
              {bannerArray_side?.map((result) => (
                <div className="md:mt-0 mt-5 mb-5">
                  <a className="" href={result.url} aria-label="banner" {...result.linktype !== '内部リンク' ? {target: '_blank', rel: 'noopener noreferrer'}: {}}>
                    <span className="hidden">{result.text}</span>
                    <div className="">
                      <GatsbyImage
                        image={getImage(result.image?.localFile?.childImageSharp?.gatsbyImageData)}
                        alt={result.title}
                      />
                    </div>
                  </a>
                </div>
              ))}

              {/*関連記事 */}
              <h3 className="mt-13 md:mt-8 mb-5 font-semibold flex ">関連記事</h3>
              <div className="flex-wrap justify-between mb-10 md:block flex">
                {relatedList}
              </div>
              <div className="mb-10">
                {/*カテゴリ検索（PC） */}
                {searchList}
                {/*PCワード検索 */}
                {wordSearchContentPC}
              </div>
              {/* PCプロフィール */}
              {profilePC}
            </div>
          </aside>
        </div>
        <div>
          {/*ワード検索 */}
          {wordSearchContentMB}

         {/* MBプロフィール */}
          {profileMB}

        </div>
        {/* 固定フッター */}
        <FixedFoot fixPattern='archive' item={SearchCategList().Sub} />
      </Layout>
    </>
  )
}

export const query = graphql`
  query searchquery {
    allWpPostData: allWpKaigaiFx(sort: {fields: modified, order: DESC}) {
      edges {
        node {
          title
          slug
          excerpt
          content
          date(formatString: "YYYY/MM/DD")
          modified(formatString: "YYYY/MM/DD")
          featuredImage {
            node {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: BLURRED
                    layout: CONSTRAINED
                    transformOptions: { cropFocus: CENTER }
                  )
                }
              }
            }
          }
          fxCategories {
            nodes {
              name
              slug
              count
              description
              wpParent {
                node {
                  slug
                  name
                }
              }
            }
          }
          fxTags {
            nodes {
              name
              slug
              wpParent {
                node {
                  slug
                  name
                }
              }
            }
          }
          acfSettings {
            eyeCatchingText1
            eyeCatchingText2
          }
        }
      }
    }
    imagesettingData: allWpImagesettings {
      edges {
        node {
          title
          slug
          smartCustomFields {
            broker_name
            archive_text1
            archive_text2
            text_position
            font_color
            image
          }
          imagesetting {
            bannerSettingsGroup {
              fieldGroupName
              bannerArea1 {
                fieldGroupName
                bannerA {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
                bannerB {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
                bannerC {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
              }
              bannerArea2 {
                fieldGroupName
                bannerA {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
                bannerB {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
                bannerC {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
              }
              bannerArea3 {
                fieldGroupName
                bannerA {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
                bannerB {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
                bannerC {
                  title
                  text
                  url
                  linkType
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          quality: 100
                          placeholder: BLURRED
                          layout: CONSTRAINED
                          transformOptions: { cropFocus: CENTER }
                        )
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default SearchResults